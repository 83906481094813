.ant-btn{
  line-height: 1 !important;
}
[class*="ant-"] {
  font-feature-settings: normal;
  font-variant: normal;
}
.ant-image-preview-img-wrapper{
  z-index: 2;
  top: 40px;
  bottom: 25px;
  .ant-image-preview-img{
    @apply inline-block;
    @apply bg-white;
    @apply p-5;
  }
}
.ant-form-item-required{
  &::before{
    @apply ml-1 #{!important};
  }
  @apply flex-row-reverse;
}
.ant-form-item-label{
  @apply font-inter-semibold;
}
