
// @tailwind base;
@tailwind components;
@tailwind utilities;
@import './custom/tailwind/';


/* You can add global styles to this file, and also import other style files */
@import '../../../node_modules/ng-zorro-antd/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/button/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/grid/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/menu/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/tabs/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/table/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/form/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/dropdown/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/input/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/notification/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/message/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/divider/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/modal/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/popconfirm/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/alert/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/skeleton/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/image/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/radio/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/result/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/select/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/progress/style/index.min.css';
@import './custom/ant/';

@import '~quill/dist/quill.snow.css';

@font-face {
  font-family: 'Inter-Regular';
  src: url(./assets/fonts/Inter-Regular.woff2) format('woff2');
}
@font-face {
  font-family: 'Inter-SemiBold';
  src: url(./assets/fonts/Inter-SemiBold.woff2) format('woff2');
}
@font-face {
  font-family: 'Satoshi-Bold';
  src: url(./assets/fonts/Satoshi-Bold.woff2) format('woff2');
}

* {
  color: inherit;
  margin: 0;
}
html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: auto;
  -webkit-overflow-scrolling: touch;
  line-height: 1.5;
  color: #4D5358;
  @apply font-inter-regular;
}

.doc-viewer{
  .ant-modal-close{
    @apply bg-white;
    right: -56px;
    .ant-modal-close-x{
      font-size: 22px;
      @apply flex;
      @apply items-center;
      @apply justify-center;
    }
  }
}

.ant-image-preview-img {
  max-width: 1199px !important;
  @apply p-5 #{!important};
  @apply bg-white;
}
.ant-form-item input{
  @apply shadow-none #{!important};
}
input.ng-dirty.ng-invalid{
  @apply border border-solid border-red-500;
}
validation-messages{
  font-size: 13px;
  @apply text-red-500;
  font-style: italic;
  margin-top: 2px;
}
